import React, { ReactElement, FC } from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"
const Page: FC<{ data: any }> = ({ data }) => {
  return (
    <Layout>
      <Seo title="Profil verejného obstarávania" />
      <h1 className="pb-2 text-3xl font-bold">Profil verejného obstarávania</h1>
      <Content data={data} />
    </Layout>
  )
}

const Content: FC<{ data: any }> = ({ data }) => {
  return (
    <section>
      <p className="pb-4">
        V zmysle Zákona NR SR č. 25/2006 Z.z. o verejnom obstarávaní a o zmene a
        doplnení niektorých zákonov v znení neskorších predpisov zriaďuje
        Rímskokatolícka cirkev, farnosť sv. Mikuláša, Prešov na webovom sídle
        svoj „Profil verejného obstarávateľa“, v ktorom bude uverejňovať
        informácie týkajúce sa verejného obstarávania.
      </p>
      <div className="flex flex-col md:flex-row-reverse">
        <div className="w-full sm:w-1/2 md:w-full max-w-lg mb-4">
          <Img
            alt="Konkatedrála sv. Mikuláša"
            sizes={data.konkatedralaImage.childImageSharp.fluid}
            className="rounded-lg m-auto"
          />
        </div>
        <div className="flex-grow">
          <h3 className="pb-2 text-xl font-bold">Verejný obstarávateľ</h3>

          <p>Rímskokatolícka farnosť sv. Mikuláša, Prešov</p>
          <p></p>
          <h3 className="py-2 text-xl font-bold">Sídlo</h3>
          <p>Hlavná 81, 080 01 Prešov</p>
          <h3 className="py-2 text-xl font-bold">Štatutárny zástupca</h3>
          <p>PhDr. Mgr. Jozef Dronzek, PhD., farár - dekan</p>
          <h3 className="py-2 text-xl font-bold">Kontakty</h3>
          <p>
            <span className="w-20 inline-block">Telefón:</span>051/7733500, 0918
            350 484
          </p>
          <p>
            <span className="w-20 inline-block">E-mail:</span>
            <a href="mailto:po.mesto@abuke.sk">po.mesto@abuke.sk</a>
          </p>
          <p>
            <span className="w-20 inline-block">web:</span>
            <a href="https://presov.rimkat.sk">https://presov.rimkat.sk</a>
          </p>
          <h3 className="py-2 text-xl font-bold">Ďalšie údaje</h3>
          <p>
            <span className="w-20 inline-block">IČO:</span> 17147000
          </p>
          <p>
            <span className="w-20 inline-block">DIČ:</span> 2021225800
          </p>
          <p>
            <span className="w-20 inline-block">IČ DPH:</span> SK2021225800
          </p>

          <p className="py-3">
            Zapísaná v Zozname cirkevných organizácií Ministerstva kultúry SR
          </p>

          <p>Bankové spojenie: ČSOB, a.s., pobočka Prešov</p>
          <p>Číslo účtu IBAN: SK02 7500 0000 0040 0800 8582</p>
        </div>
      </div>
      <p className="py-3">
        Viac informácii nájdete na stránke{" "}
        <Link to="/verejne-obstaravanie" className="font-bold">
          verejného obstarávania
        </Link>
        .
      </p>
    </section>
  )
}

export const query = graphql`
  query {
    konkatedralaImage: file(relativePath: { eq: "konkatedrala.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Page
